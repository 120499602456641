<template>
  <div>
    <z-header></z-header>
    <div class="min_width banner_image_box">
      <img src="https://qn.kemean.cn/upload/202007/06/img_scheme.png" />
      <div>
        <h1>一站式互联网+解决方案领导品牌</h1>
        <span>致力于技术与业务深度融合，为各行业企业互联网项目快速落地</span>
      </div>
    </div>
    <div class="page_center case_box">
      <div class="page_title">行业APP开发解决方案 · CASE</div>
      <div class="description">
        多年移动互联网开发与行业经验的沉淀，覆盖二十余种主流行业，对焦主流的、热门的行业，
        为企业商家打造符合业务发展需要的APP开发整体解决方案服务
      </div>
      <div class="program_list_box">
        <router-link
          v-for="(item, index) of programList"
          :key="index"
          class="program_list"
          tag="div"
          :to="'/newsDetails/' + item.objId"
        >
          <div class="image">
            <img :src="item.headImg" :alt="item.title" />
          </div>
          <span>{{ item.title }}</span>
        </router-link>
      </div>
      <z-page :current-page="pageNo" :total="pages" @change="getProgramList" />
    </div>

    <z-footer></z-footer>
  </div>
</template>

<script>
import zPage from "@/components/common/page.vue";
export default {
  components: {
    zPage,
  },
  data() {
    return {
      programList: [],
      pageNo: 1,
      total: 1,
      pages: 1,
      baseInfo: {},
    };
  },
  created() {
    this.getProgramList(1);
  },
  methods: {
    onPageJump(url) {
      this.$router.push(url);
    },
    onJumpNewsDetails(objId) {
      // let url = window.location.protocol + "//" + window.location.host;
      // url += "/newsDetails/" + objId;
      // window.open(url);
      this.$router.push("/newsDetails/" + objId);
    },
    getProgramList(size) {
      if (size) {
        this.pageNo = size;
      }
      // eslint-disable-next-line prefer-const
      let httpData = {
        pageNo: this.pageNo,
        pageSize: 12,
        type: 3007,
      };
      if (this.classifyIndex >= 0) {
        httpData.categoryId = this.classifyList[this.classifyIndex].objId;
      }
      this.$http.post("api/article/v1/list", httpData).then((res) => {
        let programData = res.data;
        this.total = programData.count;
        this.programList = programData.data;
        this.pages = programData.pages;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner_image_box {
  height: 500px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 48px;
      line-height: 48px;
      color: #ffffff;
    }
    span {
      font-size: 24px;
      color: #ffffff;
      margin-top: 34px;
    }
  }
}

.case_box {
  padding: 80px 0;
  .description {
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: #333333;
  }
  .program_list_box {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    .program_list {
      position: relative;
      width: 290px;
      height: 290px;
      margin-right: 40px;
      margin-bottom: 10px;
      overflow: hidden;
      cursor: pointer;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:hover {
        .image img {
          transform: scale(1.2);
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
      }
      .image {
        width: 100%;
        height: 100%;
        img {
          transition: transform 0.4s;
          width: 100%;
          height: 100%;
        }
      }
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        font-size: 28px;
        color: #ffffff;
        z-index: 2;
        font-weight: bold;
      }
    }
  }
}

.page_title {
  font-size: 32px;
  color: #333333;
  text-align: center;
  &.white {
    color: #fff;
  }
}
</style>